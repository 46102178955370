import React from 'react'
import { Helmet } from 'react-helmet'

import Heading, { Types } from '@components/atoms/heading/heading'
import Text from '@components/atoms/text/text'
import Metadata from '@components/atoms/metadata/metadata'

import Container from '@layouts/container/container'

import styles from './cookies.module.scss'

export default ({ location, pageContext: { courses } }) => {
    return (
        <Container courses={courses} className={styles.container}>
            <Helmet>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <Metadata url={location?.href} title="MW Training | Cooke Policy" />
            <Heading className={styles.heading1} type={Types.H1} text="MW Training Cookie & Privacy Policy" />
            <Heading className={styles.heading2} type={Types.H2} text="Our stance on privacy" />
            <Text className={styles.text}>
                We strongly believe that <strong>your data belongs to you</strong>. For this reason we don't collect non identifiable
                information about our users. Only tracking the bare minimum to track bugs and understand how our website is being used.
            </Text>
            <Heading className={styles.heading2} type={Types.H2} text="What information does MW Training Collect?" />

            <Heading className={styles.heading3} type={Types.H3} text="Cookies set by MW Training" />
            <Text className={styles.text}>
                <strong>_ca</strong> - Set by MW Training to determine if the user has dismissed the cookie popup
            </Text>

            <Heading className={styles.heading3} type={Types.H3} text="Cookies set by Google Analytics" />
            <Text className={styles.text}>
                Google Analytics is a popular tracking solution. This service provides us with statistics about how users interact with our
                website.
            </Text>
            <Text className={styles.text}>
                <strong>_utma, _utmz, _utmb, _utmc, _utmt, _ga, _gat, _gid</strong> - Set by Google Analytics uses these cookies to provide
                MW Training with analytical information about the use of our website.
            </Text>

            <Heading className={styles.heading2} type={Types.H2} text="How do I prevent cookies being set" />
            <Text className={styles.text}>
                You can prevent the website from setting cookies by activating the setting on your browser that allows you to refuse the
                setting of all or some cookies. Unless you have adjusted your browser setting so that it will refuse cookies, cookies will
                be set as soon you visit our website.
            </Text>
            <Heading className={styles.heading2} type={Types.H2} text="Changes to this policy" />
            <Text className={styles.text}>
                Changes to this privacy policy may periodically be made and posted here. We suggest you visit this page for updates.
            </Text>
            <Text className={styles.date}>Policy updated on 18/08/2020</Text>
        </Container>
    )
}
